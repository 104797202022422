.hireme{
    font-size:xx-large;
    text-align: center;
    margin-top:40px;
}
.skillsmain{
    margin-top:40px;
    /* border:solid grey 2px; */
}

.skillscointainer{
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
    /* border:solid blue 2px; */
    justify-content: center;
    margin-top:40px;
    margin-bottom:40px;
}

.devicons{
    font-size: xx-large;
    transform: scale(2);
    margin-left:60px;
    color:grey;
}
.reacticon{
    font-size: x-large;
    transform: scale(2);
    color:grey;
    /* background-color: grey; */
}
